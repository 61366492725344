<!-- 方案介绍 -->
<template>
  <div class="home-introduce">
    <div class="introduce-content">
      <!-- 标题 -->
      <div class="home-introduce-title">
        <div class="home-title">产品解决方案</div>
        <div class="sub">高效提出方案，解决您的需求</div>
      </div>
      <div class="introduce-tabs">
        <div ref="tabsHeader" class="introduce-tabs-bar">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            class="tab-item"
            :class="{ active: activeTabIndex === index }"
            @click="activateTab(index)"
          >
            {{ tab.label }}
          </div>
          <!-- 激活指示器，通过 transform 实现滚动动画 -->
          <div class="active-indicator" :style="activeIndicatorStyle" />
        </div>
        <div class="case-text" @click="handleAllSolutions">
          <span>全部解决方案</span>
          <i class="el-icon-right" />
        </div>
      </div>
      <!-- 内容列表 -->
      <div class="home-introduce-content">
        <div ref="mySwiper" :options="swiperOption">
          <div v-if="activeTabIndex === 0" class="swiper_slide_item">
            <div
              v-for="(item, index) in introduceList"
              :key="index"
              class="home-introduce-content-item"
              :style="{
                backgroundImage: `url(${item.img})`,
              }"
              :class="[
                { 'active': activeIndex === index },
                { 'default-wide': index === 1 && activeIndex === -1 },
                { 'inactive': activeIndex === index || (index === 1 && activeIndex === -1) }
              ]"
              @mouseenter="setActive(index)"
              @mouseleave="clearActive"
            >
              <div class="home-introduce-content-item-title">
                <span>{{ item.title }}</span>
              </div>
              <div v-if="activeIndex === index || (index === 1 && activeIndex === -1)" class="home-introduce-content-item-tip">
                <span>{{ item.tip }}</span>
                <span v-if="item.tip2"><br>{{ item.tip2 }}</span>
              </div>
            </div>
          </div>
          <div v-else class="swiper_slide_item">
            <div
              v-for="(item, index) in introduceList2"
              :key="'int' + index"
              class="home-introduce-content-item"
              :style="{
                backgroundImage: `url(${item.img})`,
              }"
              :class="[
                { 'active': activeIndex === index },
                { 'default-wide': index === 0 && activeIndex === -1 },
                { 'inactive': activeIndex === index || (index === 0 && activeIndex === -1) }
              ]"
              @mouseenter="setActive(index)"
              @mouseleave="clearActive"
            >
              <div class="home-introduce-content-item-title">
                <span>{{ item.title }}</span>
              </div>
              <div v-if="activeIndex === index || (index === 0 && activeIndex === -1)" class="home-introduce-content-item-tip">
                <span>{{ item.tip }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide class="swiper_slide_item">
            <div
              v-for="(item, index) in introduceList"
              :key="index"
              class="home-introduce-content-item"
              :style="{
                backgroundImage: `url(${item.img})`,
              }"
              :class="[
                { 'active': activeIndex === index },
                { 'default-wide': index === 1 && activeIndex === -1 },
                { 'inactive': activeIndex === index || (index === 1 && activeIndex === -1) }
              ]"
              @mouseenter="setActive(index)"
              @mouseleave="clearActive"
            >
              <div class="home-introduce-content-item-title">
                <span>{{ item.title }}</span>
              </div>
              <div v-if="activeIndex === index || (index === 1 && activeIndex === -1)" class="home-introduce-content-item-tip">
                <span>{{ item.tip }}</span>
                <span v-if="item.tip2"><br>{{ item.tip2 }}</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper_slide_item">
            <div
              v-for="(item, index) in introduceList2"
              :key="'int' + index"
              class="home-introduce-content-item"
              :style="{
                backgroundImage: `url(${item.img})`,
              }"
              :class="[
                { 'active': activeIndex === index },
                { 'default-wide': index === 0 && activeIndex === -1 },
                { 'inactive': activeIndex === index || (index === 0 && activeIndex === -1) }
              ]"
              @mouseenter="setActive(index)"
              @mouseleave="clearActive"
            >
              <div class="home-introduce-content-item-title">
                <span>{{ item.title }}</span>
              </div>
              <div v-if="activeIndex === index || (index === 0 && activeIndex === -1)" class="home-introduce-content-item-tip">
                <span>{{ item.tip }}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
export default {
  name: 'HomeIntroduce',
  components: {
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          label: '智慧病房'
        },
        {
          label: '区域肿瘤/慢病防治平台'
        }
      ],
      introduceList: [
        {
          title: '智慧医院整体解决方案',
          img: require('@/assets/img/introduce1.png'),
          tip: '物联网智慧感知信息集成服务平台',
          tip2: '让智慧病房更智慧'
        },
        {
          title: '移动护理系统',
          img: require('@/assets/img/introduce4.png'),
          tip: '医疗移动护理系统是借助移动信息技术，专为医疗护理工作打造的创新型应用系统，旨在打破传统护理工作的空间限制，让护理流程更便捷、高效。'
        },
        {
          title: '护理管理系统',
          img: require('@/assets/img/introduce3.png'),
          tip: '护理管理信息系统具有管理全院护理人力资源、考核、培训、意外事件、满意度、工作量统计、危重病人访视、护理质控检查、护士长排班、实习护生、进修人员等功能。护士长科内提交信息，护理部根据电脑自动提示审管全院上报。利用护理管理系统,护理部不再需要人工整理科室上报文件，自动生成各类月度、季度、半年、全年统计图表，如意外事件发生数、平均满意度与排名、考核结果、培训情况、三级护理质量检查合格率/均分、全院人员考勤与值班小时数等等。护理管理系统可有效提高护理部管理执行力，是高效管理必备助手'
        },
        {
          title: '护士站智能交互系统',
          img: require('@/assets/img/introduce2.png'),
          tip: '护士站智能交互系统通过放在护士站的智能交互大屏，与病房终端、医院His系统进行数据互联互通，实现院内数据统一整合显示。包括病区概况、患者信息、今日治疗、备忘录、交班信息、呼叫响应、健康宣教、护士排班、危急值预警等信息，帮助医护人员快速获取工作重点，优化工作流程，提高工作效率。'
        }
      ],
      introduceList2: [
        {
          title: '区域肿瘤防治系统',
          img: require('@/assets/img/introduce5.png'),
          tip: '区域肿瘤防治数字化平台是通过现代信息技术与医疗服务，提高肿瘤预防、治疗和康复水平。通过肿瘤知识的科普宣教，提升公众防癌意识、降低可预防肿瘤发生率；扩大居民的肿瘤筛查范围实现早诊早治；为肿瘤患者提供全“治疗、康复、随访”全生命周期的闭环管理。构建“防、筛、诊、治、康”五位一体的肿瘤防治体系。'
        }, {
          title: '区域专病/慢病管理平台',
          img: require('@/assets/img/introduce6.png'),
          tip: '区域慢病管理平台依据慢病管理规范和诊疗路径设计，应用大数据、物联网及人工智能技术，实现对慢病预防、诊断、治疗、康复各环节的全过程健康管理，集体征监测、健康评估、 病程管理、生活方式干预为一体，为区域慢病患者实现健康档案动态化 、 健康检测常态化 、 慢病管理数字化、健康教育普及化以及数据分析智能化。'
        }
      ],
      activeIndicatorStyle: {},
      activeIndex: -1,
      swiperOption: {
        direction: 'horizontal',
        simulateTouch: false
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  methods: {
    activateTab(index) {
      this.activeTabIndex = index;
      if (index === 0) {
        this.activeIndicatorStyle = {
          width: '140px',
          transform: 'translateX(0)'
        };
      } else if (index === 1) {
        this.activeIndicatorStyle = {
          width: '244px',
          transform: 'translateX(178px)'
        };
      }
      // this.swiper.slideTo(Number(index), 500, false);
    },
    go(pathUrl) {
      if (pathUrl) {
        this.$router.push({
          path: pathUrl
        });
      }
    },
    handleAllSolutions() {
      this.$router.push({
        path: '/allSolutions'
      });
    },
    setActive(index) {
      this.activeIndex = index;
    },
    clearActive() {
      this.activeIndex = -1;
    }
  }
};
</script>

<style lang='scss' scoped>
.home-introduce {
  width: 100%;
  background: #f7f8fe;
  padding: 80px 0 0;
  .introduce-content {
    width: 1200px;
    margin: 0 auto;
  }
  .home-introduce-title {
    margin-bottom: 40px;
    .home-title {
      line-height: 50px;
      font-size: 36px;
      color: #333333;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .sub {
      font-size: 16px;
      color: #5d7392;
      line-height: 22px;
    }
  }
  .introduce-tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 52px;
    .introduce-tabs-bar {
      display: flex;
      position: relative;
      width: 490px;
      height: 60px;
      background: #ffffff;
      border-radius: 14px;
      padding: 10px 24px;
      .tab-item {
        position: relative;
        z-index: 1;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: #333;
        font-size: 16px;
        margin-left: 38px;
        margin-right: 80px;
        cursor: pointer;
        &.active {
          color: #fff;
        }
        &:nth-child(2) {
          margin-right: 0;
        }
      }
      .active-indicator {
        position: absolute;
        width: 140px;
        top: 10px;
        left: 24px;
        height: 40px;
        background-color: #1a6deb;
        border-radius: 10px;
        z-index: 0;
        transition: all 0.3s ease;
      }
    }
    .case-text {
      font-size: 16px;
      color: #333;
      cursor: pointer;
      span {
        margin-right: 6px;
      }
    }
  }
  .home-introduce-content {
    .swiper_slide_item {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    .home-introduce-content-item {
      position: relative;
      flex: 1;
      height: 486px;
      padding: 38px 32px;
      background: rgba(0,0,0,.5);
      border-radius: 12px;
      transition: all 0.3s ease-in-out;
      background-size: cover;
      background-position: center;
      overflow: hidden; /* 确保遮罩不超出卡片 */
      .home-introduce-content-item-title {
        line-height: 33px;
        font-size: 20px;
        color: #ffffff;
        margin-bottom: 8px;
        font-weight: 600;
      }
      .home-introduce-content-item-tip {
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        color: #ffffff;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none; /* 防止遮罩层阻挡鼠标事件 */
        transition: opacity 0.3s ease-in-out 0.1s;
      }
      &.inactive::after {
        opacity: 0;
        filter: brightness(0.8) blur(1px);
        transform: scale(0.98);
      }
    }
    .default-wide {
      flex: 3.5;
    }
    .active {
      flex: 3.5;
    }
  }
}
</style>
