<!-- banner -->
<template>
  <!-- 顶部图片or轮播图 -->
  <div class="home-banner">
    <div class="carousel">
      <video class="video" autoplay="autoplay" muted="muted" loop="loop" src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/banner.mp4" />
      <div class="banner-img">
        <div class="img-warp">
          <img src="~@/assets/img/banner-img.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeBanner'
};
</script>

<style lang='scss' scoped>
.home-banner {
  height: 620px;
  .carousel {
    width: 100%;
    height: 100%;
    position: relative;
    .content {
      position: absolute;
      top: 200px;
      left: 0;
      width: 100%;
      .box {
        width: 1200px;
        margin: 0 auto;
      }
      .title {
        width: 209px;
        height: 61px;
        // font-size: 40px;
        // font-weight: 700;
        margin-bottom: 40px;
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 32px;
      }
    }
    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-img {
      width: 100%;
      height: 171px;
      position: absolute;
      display: flex;
      justify-content: center;
      top: 204px;
      .img-warp {
        width: 1200px;
      }
      img {
        width: 354px;
      }
    }
  }
  /deep/ .el-carousel__container {
    height: 650px;
  }
}
</style>
