var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-introduce"},[_c('div',{staticClass:"introduce-content"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"introduce-tabs"},[_c('div',{ref:"tabsHeader",staticClass:"introduce-tabs-bar"},[_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"tab-item",class:{ active: _vm.activeTabIndex === index },on:{"click":function($event){return _vm.activateTab(index)}}},[_vm._v("\n          "+_vm._s(tab.label)+"\n        ")])}),_vm._v(" "),_c('div',{staticClass:"active-indicator",style:(_vm.activeIndicatorStyle)})],2),_vm._v(" "),_c('div',{staticClass:"case-text",on:{"click":_vm.handleAllSolutions}},[_c('span',[_vm._v("全部解决方案")]),_vm._v(" "),_c('i',{staticClass:"el-icon-right"})])]),_vm._v(" "),_c('div',{staticClass:"home-introduce-content"},[_c('div',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[(_vm.activeTabIndex === 0)?_c('div',{staticClass:"swiper_slide_item"},_vm._l((_vm.introduceList),function(item,index){return _c('div',{key:index,staticClass:"home-introduce-content-item",class:[
              { 'active': _vm.activeIndex === index },
              { 'default-wide': index === 1 && _vm.activeIndex === -1 },
              { 'inactive': _vm.activeIndex === index || (index === 1 && _vm.activeIndex === -1) }
            ],style:({
              backgroundImage: ("url(" + (item.img) + ")"),
            }),on:{"mouseenter":function($event){return _vm.setActive(index)},"mouseleave":_vm.clearActive}},[_c('div',{staticClass:"home-introduce-content-item-title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),(_vm.activeIndex === index || (index === 1 && _vm.activeIndex === -1))?_c('div',{staticClass:"home-introduce-content-item-tip"},[_c('span',[_vm._v(_vm._s(item.tip))]),_vm._v(" "),(item.tip2)?_c('span',[_c('br'),_vm._v(_vm._s(item.tip2))]):_vm._e()]):_vm._e()])}),0):_c('div',{staticClass:"swiper_slide_item"},_vm._l((_vm.introduceList2),function(item,index){return _c('div',{key:'int' + index,staticClass:"home-introduce-content-item",class:[
              { 'active': _vm.activeIndex === index },
              { 'default-wide': index === 0 && _vm.activeIndex === -1 },
              { 'inactive': _vm.activeIndex === index || (index === 0 && _vm.activeIndex === -1) }
            ],style:({
              backgroundImage: ("url(" + (item.img) + ")"),
            }),on:{"mouseenter":function($event){return _vm.setActive(index)},"mouseleave":_vm.clearActive}},[_c('div',{staticClass:"home-introduce-content-item-title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),(_vm.activeIndex === index || (index === 0 && _vm.activeIndex === -1))?_c('div',{staticClass:"home-introduce-content-item-tip"},[_c('span',[_vm._v(_vm._s(item.tip))])]):_vm._e()])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-introduce-title"},[_c('div',{staticClass:"home-title"},[_vm._v("产品解决方案")]),_vm._v(" "),_c('div',{staticClass:"sub"},[_vm._v("高效提出方案，解决您的需求")])])}]

export { render, staticRenderFns }