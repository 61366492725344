<!-- 我们的优势 -->
<template>
  <div class="home-advantage">
    <div class="advantage-content">
      <!-- 标题 -->
      <div class="home-advantage-title">
        <div class="home-title">我们的优势</div>
        <div class="sub">结合产品优势，高效融合平台，拓展开放度</div>
      </div>
      <div class="home-advantage-content">
        <div class="bar-content">
          <div class="tab-bar">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              class="tab"
              :class="{ active: activeTabIndex === index }"
              @click="activateTab(index)"
            >
              {{ tab.label }}
            </div>
            <!-- 激活指示器，通过 transform 实现滚动动画 -->
            <div class="active-indicator" :style="indicatorStyle" />
          </div>
        </div>
        <!-- 右侧内容区域 -->
        <div class="tab-content">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide list-item">
                <div class="left-text">
                  <div class="left-title">{{ tabs[activeTabIndex].label }}</div>
                  <div class="left-tip">{{ tabs[activeTabIndex].tip }}</div>
                </div>
                <div class="right-img">
                  <img :src="tabs[activeTabIndex].img" alt="">
                </div>
              </div>
              <!-- <div v-for="(tab, index) in tabs" :key="index" class="swiper-slide list-item">
                <div class="left-text">
                  <div class="left-title">{{ tab.label }}</div>
                  <div class="left-tip">{{ tab.tip }}</div>
                </div>
                <div class="right-img">
                  <img :src="tab.img" alt="">
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
// import Swiper from 'swiper';
export default {
  name: 'HomeAdvantage',
  components: {
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          label: '生态',
          tip: '充分吸收先行者的成功经验和自身数字化实践积累下，致力于和客户伙伴联合创新，和数字化共建者们一起沉淀。',
          img: require('@/assets/img/advantage-1.png')
        },
        {
          label: '融合',
          tip: '以物联网为核心ICT技术、区别于以设备管理为主的主流物联网平台，AllOne IoT和第三方平台管理模块加大了对客户伙伴的南向开放度，更大程度融合生态中各方的数据和业务。',
          img: require('@/assets/img/advantage-2.png')
        },
        {
          label: '开放',
          tip: '组件化、模块化、多环境部署能力和集成客户伙伴的平台能力是对客户伙伴开放度的保证。',
          img: require('@/assets/img/advantage-3.png')
        },
        {
          label: '高效',
          tip: '全生命周期产品和服务的生态能力，以及预集成的平台综合能力，支持项目高效且高质量交付。',
          img: require('@/assets/img/advantage-4.png')
        }
      ],
      swiperOption: {
        direction: 'vertical',
        simulateTouch: false
      }
    };
  },
  computed: {
    // 根据当前激活项计算指示器的位置，这里假设每个tab高度为40px
    indicatorStyle() {
      return {
        transform: `translateY(${this.activeTabIndex * 110}px)`
      };
    }
  },
  mounted() {
    // 初始化 Swiper
    // this.$nextTick(() => {
    //   this.swiperInstance = new Swiper('.swiper-container', {
    //     ...this.swiperOptions
    //   });
    // });
    // }
  },
  methods: {
    activateTab(index) {
      this.activeTabIndex = index;
      // this.swiperInstance.slideTo(index, 700, false);
    }
  }
};
</script>

<style lang='scss' scoped>
.home-advantage {
  width: 100%;
  height: 680px;
  background: #f7f8fe;
  padding: 80px 0 0;
  .advantage-content {
    width: 1200px;
    margin: 0 auto;
  }
  .home-advantage-title {
    margin-bottom: 40px;
    .home-title {
      line-height: 50px;
      font-size: 36px;
      color: #333333;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .sub {
      font-size: 16px;
      color: #5d7392;
      line-height: 22px;
    }
  }
  .home-advantage-content {
    height: 480px;
    background: linear-gradient(180deg,#bfdeff, #f9fcff 53%), linear-gradient(21deg,rgba(255,255,255,0.00) 16%, rgba(150,223,255,0.50) 100%);
    border-radius: 20px;
    display: flex;
    /* 左侧tab栏 */
    .bar-content {
      padding: 20px 0;
      border-right: 1px solid #eee;
      width: 180px;
    }
    .tab-bar {
      width: 100%;
      position: relative;
    }

    /* 每个tab项 */
    .tab {
      width: 100%;
      height: 110px;
      line-height: 110px;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s;
      color: #333;
      font-size: 18px;
      font-weight: 600;
    }

    /* 激活的tab */
    .tab.active {
      color: #0279ff;
      background: #cbe3ff;
    }

    /* 激活指示器，使用 transition 实现动画 */
    .active-indicator {
      position: absolute;
      width: 3px;
      height: 110px;
      background-color: #1a6deb;
      left: 0;
      top: 0;
      transition: transform 0.3s;
    }

    /* 右侧内容区域 */
    .tab-content {
      width: calc(100% - 180px);
      // display: flex;
      // justify-content: space-between;
      // padding: 0 30px 0 60px;
      .swiper-container {
        width: 100%;
        height: 100%;
        .swiper-slide {
          width: 100%;
          display: flex;
          // justify-content: space-between;
          padding: 0 30px 0 60px;
        }
      }
      .left-text {
        width: 360px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left-title {
          font-size: 32px;
          line-height: 45px;
          color: #333333;
          font-weight: 600;
          margin-bottom: 16px;
        }
        .left-tip {
          font-size: 16px;
          color: #333;
          line-height: 24px;
        }
      }
      .right-img {
        width: 536px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        img {
          width: 100%;
          height: 452px;
        }
      }
    }
  }
}
</style>
