<!-- 方案介绍 -->
<template>
  <div class="home-case">
    <div class="case-content">
      <!-- 标题 -->
      <div class="home-case-title">
        <div class="home-title">合作案例</div>
        <div class="sub">合作高效，良好反馈</div>
      </div>
      <!-- 内容列表 -->
      <div class="home-case-content">
        <div
          v-for="(item, index) in caseList"
          :key="index"
          class="home-case-content-item"
          :style="{
            backgroundImage: `url(${item.img})`,
            backgroundSize: '100% 100%'
          }"
        >
          <div class="home-case-content-item-title">
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Homecase',
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          label: '智慧病房'
        },
        {
          label: '区域肿瘤/慢病防治平台'
        }
      ],
      caseList: [
        {
          title: '浙江省中医院',
          img: require('@/assets/img/case1.png')
        }, {
          title: '浙江大学医院院附属邵逸夫医院',
          img: require('@/assets/img/case2.png')
        }, {
          title: '江西抚州第一人民医院',
          img: require('@/assets/img/case3.png')
        },
        {
          title: '江西景德镇市第一人民医院',
          img: require('@/assets/img/case5.png')
        },
        {
          title: '河南省荣康医院',
          img: require('@/assets/img/case4.png')
        }
        // {
        //   title: '句容县人民医院',
        //   img: require('@/assets/img/case5.png')
        // }
      ]
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang='scss' scoped>
.home-case {
  width: 100%;
  background: #f7f8fe;
  padding: 80px 0 0;
  .case-content {
    width: 1200px;
    margin: 0 auto;
  }
  .home-case-title {
    margin-bottom: 60px;
    .home-title {
      line-height: 50px;
      font-size: 36px;
      color: #333333;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .sub {
      font-size: 16px;
      color: #5d7392;
      line-height: 22px;
    }
  }
  .home-case-content {
    display: flex;
    justify-content: space-between;
    .home-case-content-item {
      position: relative;
      width: 193px;
      height: 214px;
      .home-case-content-item-title {
        position: absolute;
        left: 0;
        right: 0;
        line-height: 17px;
        font-size: 12px;
        color: #ffffff;
        bottom: 37px;
        text-align: center;
      }
    }
  }
}
</style>
