// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/ecosystem-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".home-ecosystem[data-v-cfb058a8]{width:100%;padding:80px 0 0;background:#f7f8fe}.home-ecosystem .ecosystem-content[data-v-cfb058a8]{width:100%;height:320px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.home-ecosystem .ecosystem-content .ecosystem-container[data-v-cfb058a8]{width:1200px;margin:0 auto}.home-ecosystem .home-ecosystem-title[data-v-cfb058a8]{margin-bottom:60px}.home-ecosystem .home-ecosystem-title .home-title[data-v-cfb058a8]{line-height:50px;font-size:36px;color:#333;margin-bottom:6px;font-weight:600}.home-ecosystem .home-ecosystem-title .sub[data-v-cfb058a8]{font-size:16px;color:#5d7392;line-height:22px}.home-ecosystem .home-ecosystem-content[data-v-cfb058a8]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;position:relative;width:100%}.home-ecosystem .home-ecosystem-content .photo-container[data-v-cfb058a8]{width:100%;overflow:hidden}.home-ecosystem .home-ecosystem-content .photo-track[data-v-cfb058a8]{display:-webkit-box;display:-ms-flexbox;display:flex;width:3080px;-webkit-animation:scroll-data-v-cfb058a8 15s linear infinite;animation:scroll-data-v-cfb058a8 15s linear infinite}.home-ecosystem .home-ecosystem-content .photo-item[data-v-cfb058a8]{height:80px;-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;margin-right:20px}.home-ecosystem .home-ecosystem-content .photo-item img[data-v-cfb058a8]{width:200px}@-webkit-keyframes scroll-data-v-cfb058a8{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(-50%);transform:translateX(-50%)}}@keyframes scroll-data-v-cfb058a8{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(-50%);transform:translateX(-50%)}}", ""]);
// Exports
module.exports = exports;
