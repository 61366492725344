<!-- 方案介绍 -->
<template>
  <div class="home-ecosystem">
    <div class="ecosystem-content">
      <div class="ecosystem-container">
        <!-- 标题 -->
        <div class="home-ecosystem-title">
          <div class="home-title">生态合作伙伴</div>
          <div class="sub">生态合作，互利共赢</div>
        </div>
        <!-- 内容列表 -->
        <div class="home-ecosystem-content">
          <div class="photo-container">
            <div class="photo-track">
              <!-- 将图片数组复制一份，保证无缝衔接 -->
              <div
                v-for="(photo, index) in loopedPhotos"
                :key="index"
                class="photo-item"
              >
                <img :src="photo.img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css';
export default {
  name: 'HomeEcosystem',
  components: {
  },
  data() {
    return {
      imgList: [
        {
          img: require('@/assets/img/ecosystem1.png')
        },
        {
          img: require('@/assets/img/ecosystem2.png')
        },
        {
          img: require('@/assets/img/ecosystem3.png')
        },
        {
          img: require('@/assets/img/ecosystem4.png')
        },
        {
          img: require('@/assets/img/ecosystem5.png')
        },
        {
          img: require('@/assets/img/ecosystem6.png')
        },
        {
          img: require('@/assets/img/ecosystem7.png')
        }
      ]
    };
  },
  computed: {
    loopedPhotos() {
      return [...this.imgList, ...this.imgList];
    }
  },
  methods: {
  }
};
</script>

<style lang='scss' scoped>
.home-ecosystem {
  width: 100%;
  padding: 80px 0 0;
  background: #f7f8fe;
  .ecosystem-content {
    width: 100%;
    height: 320px;
    background-image: url('~@/assets/img/ecosystem-bg.png');
    background-size: 100% 100%;
    .ecosystem-container {
      width: 1200px;
      margin: 0 auto;
    }
  }
  .home-ecosystem-title {
    margin-bottom: 60px;
    .home-title {
      line-height: 50px;
      font-size: 36px;
      color: #333333;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .sub {
      font-size: 16px;
      color: #5d7392;
      line-height: 22px;
    }
  }
  .home-ecosystem-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    .photo-container {
      width: 100%;
      overflow: hidden; /* 隐藏超出部分 */
    }
    .photo-track {
      display: flex;
      /* 为了让两组图片拼接成一个连续的整体，设置宽度为200% */
      width: 3080px; // 此处的宽度是图片宽度加上间距的总和
      /* CSS 动画：平滑将整个轨道向左移动 */
      animation: scroll 15s linear infinite;
    }

    .photo-item {
      height: 80px;
      flex: 0 0 auto; /* 保持原尺寸，不自动收缩 */
      margin-right: 20px; /* 图片间距，可根据需要调整 */
      img {
        width: 200px;
      }
    }

    /* 定义滚动动画：从初始位置移动到负半个容器宽度 */
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
  }
}
</style>
