<!-- 首页 -->
<template>
  <div class="wrapper pageBox">
    <!-- 顶部图片or轮播图 -->
    <HomeBanner id="HomeBanner" ref="HomeBanner" />
    <!-- 导航条 -->
    <NavBar id="NavBar" ref="NavBar" />
    <!-- 方案介绍 -->
    <HomeIntroduce id="introduce_div" ref="HomeIntroduce" />
    <!-- 我们的优势 -->
    <HomeAdvantage id="advantage_div" ref="HomeAdvantage" />
    <!-- 合作案例 -->
    <Case id="case_div" ref="Case" />
    <!-- 行业生态伙伴 -->
    <HomeEcosystemPartner id="ecosystem_partner_div" ref="HomeEcosystemPartner" />
    <!-- 新闻资讯 -->
    <!-- <HomeNews id="news_div" ref="HomeNews" :news-list="newsList" /> -->
    <!-- 加入我们 -->
    <!-- <HomeOurs id="ours_div" ref="HomeOurs" /> -->
  </div>
</template>

<script>
import NavBar from '@/components/newNavBar/index';
import HomeAdvantage from './components/advantage';
import HomeIntroduce from './components/introduce';
import Case from './components/case';
import HomeEcosystemPartner from './components/ecosystemPartner';
import HomeBanner from './components/banner';
// import HomeNews from './components/news.vue';
// import HomeOurs from './components/ours.vue';
import { getHomeNews } from '@/service/api';
export default {
  name: 'Home',
  components: { NavBar, HomeAdvantage, HomeIntroduce, Case, HomeEcosystemPartner, HomeBanner },
  data() {
    return {
      newsList: [] // 新闻列表
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    async getData() {
      const res = await getHomeNews();
      if (res.code === 200) {
        this.newsList = res.data;
      }
    }
  }
};
</script>

<style lang='scss' scoped>

</style>
